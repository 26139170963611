<template>
  <div id="errorDialog">
    <div class="material-icons">
      clear
    </div>
    {{errorText}}
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ErrorPop",
  props: {
    errorText: String
  },
  mounted() {
    setTimeout(()=>{this.clear()},5000)
  },
  methods: {
    ...mapActions('alert',['clear']),
  }
}
</script>

<style lang="scss" scoped>
#errorDialog {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
  gap:5px;
  align-self: center;
  color:white;
  background-color: #D55353;
  border-radius: 8px;
  bottom: 24px;
  right:24px;
  padding:16px;



}

@media screen and (max-width: 768px){
  #errorDialog{

    top: 120px;
    bottom:auto;
  }

}
</style>
